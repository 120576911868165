<template>
  <div class="contacts">
    <b-container>
      <h1 class="text-center mb-4">Наши <span class="blue-text">контакты</span></h1>
      <article class="about-company mb-4 text-center">
        <span><a class="blue-text" href="/">Электрик24</a></span> - команда профессионалов, имеющих огромный опыт работы в сфере электромонтажных работ.
        Мы предлагаем нашим клиентам полный комплекс услуг по электрификации квартир и загородных домов в Красноярске.
        Наши специалисты обладают всеми необходимыми знаниями и навыками, необходимыми для проведения работ любой сложности.
      </article>
      <b-row>
        <div class="col-lg-4 col-12">
          <h3>Свяжитесь с нами</h3>
          <div class="d-flex phone mt-3">
            <span class="material-icons-round my-auto mr-2 blue-text">phone</span>
            <a href="tel:89535823354" class="Blondie">
                8-953-582-33-54
            </a>
          </div>
          <div class="d-flex email mt-2">
            <span class="material-icons-outlined my-auto mr-2 blue-text">email</span>
            <a href="mailto:electrik24@mail.ru?subject = Feedback&body = Message">
                electrik24@mail.ru
            </a>
          </div>
          <hr>
          <h3>Наш адрес</h3>
          <div class="d-flex address mt-2">
            <span class="material-icons-outlined my-auto mr-2 blue-text">location_on</span> Красноярск, ул. Пушкина
          </div>
          <hr>
          <h3>Заказать звонок</h3>
          <button class="w-100 call-btn" v-b-modal.call-modal>Позвоните мне!</button>
        </div>
        <div class="col-lg-8 col-12">
          <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad91efc0b56986195e2907b9c5617a53e0f49c552cc0f806a5edf41bb6ab7687b&amp;source=constructor" width="100%" height="435" frameborder="0"></iframe>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Contacts"
}
</script>

<style scoped lang="scss">
.contacts{
  padding-top: 110px;
}

.phone, .email, .address{
  font-size: 20px;
}

.about-company{
  font-size: 20px;
}

.call-btn{
  border: none;
  background: linear-gradient(93.86deg, #3CB3FF -1.19%, #49B8FF -1.18%, #98D7FF 133.59%);
  border-radius: 50px;
  border: none;
  outline: none;
  color: white;
  padding: 10px 0;
}
</style>